










































import Component from "vue-class-component";
import Base from "@/components/core/Base.vue";
import { parse, LocalFile } from "papaparse";
import { Product } from "@/model/Product";
import { DATE_CONFIG } from "@/components/menus/menus.constants";
import { SnackType } from "@/events";
import { Menu } from "@/model/Menu";
import { menusService } from "@/services/menus.service";
import { Mutations } from "@/store/mutations";

@Component({})
export default class ImportMenus extends Base {
  file: LocalFile | null = null;

  weekList: { startDate: Date; endDate: Date }[] = [];

  startDate: Date | null = null;

  serviceCode: string | null = null;

  get menus(): Menu[] {
    return this.$store.state.menus;
  }

  mounted() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const nextYear = new Date();
    nextYear.setHours(0, 0, 0, 0);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    this.weekList = this.getWeeksList(today, nextYear);
    if (this.services.length === 1) this.serviceCode = this.services[0].code;
  }

  readFile() {
    if (this.file) {
      parse(this.file, {
        header: true,
        dynamicTyping: true,
        worker: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        complete: (results: any) => {
          const products: Product & { day: number }[] = results.data
            .filter((p: Product) => p.name && p.type && p.zoneCode)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((result: any) => ({
              ...result,
              allergens: result.allergens
                ? result.allergens
                    .trim()
                    .split(",")
                    .map((allergen: string) => allergen.trim())
                : [],
              origins: result.origins
                ? result.origins
                    .trim()
                    .split(",")
                    .map((allergen: string) => allergen.trim())
                : [],
              price: parseFloat(result.price.replace(",", "."))
            }));

          this.submit(products);
        }
      });
    }
  }

  getWeeksList(startDate: Date, endDate: Date) {
    const startDay = startDate.getDay();

    // Si la date de début n'est pas un lundi, on la décale jusqu'au prochain lundi
    if (startDay !== 1) {
      const daysToMonday = startDay === 0 ? 6 : 1 - startDay;
      startDate.setDate(startDate.getDate() + daysToMonday);
    }

    const weeks = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const weekStartDate = new Date(currentDate);
      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() + 6);
      weeks.push({
        startDate: weekStartDate,
        endDate: weekEndDate
      });
      currentDate.setDate(currentDate.getDate() + 7);
    }
    return weeks;
  }

  formatDate(date: Date) {
    return date.toLocaleString("fr-FR", DATE_CONFIG);
  }

  async submit(products: Product & { day: number }[]) {
    const customerId = this.site?.customerId;

    if (!customerId || !products.length || !this.startDate || !this.serviceCode) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }

    const menusWithServiceCode = this.menus.filter(m => m.serviceCode === this.serviceCode);
    let currentDay = 1;

    while (currentDay <= 7) {
      const currentDate = new Date(this.startDate);
      currentDate.setDate(currentDate.getDate() + currentDay);
      currentDate.setUTCHours(0, 0, 0, 0);
      const productsOfDay = products.filter(p => p.day === currentDay);
      if (productsOfDay.length) {
        const productsWithoutDay = productsOfDay.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ day, ...keepAttrs }) => keepAttrs
        ) as Product[];
        const foundMenu = menusWithServiceCode.find(
          m => currentDate.getTime() === new Date(m.date).getTime()
        );

        /* eslint-disable no-await-in-loop */
        if (foundMenu && foundMenu._id) {
          await this.updateMenu(foundMenu._id, productsWithoutDay);
        } else {
          await this.createMenu(
            customerId,
            currentDate.toISOString(),
            productsWithoutDay,
            this.serviceCode
          );
        }
        /* eslint-enable no-await-in-loop */
      }
      currentDay += 1;
    }
    this.$store.commit(Mutations.SET_MENUS, await menusService.getMenus(customerId));
    this.snack("Import des menus réalisé avec succès", SnackType.SUCCESS, 5000);
  }

  async updateMenu(id: string, products: Product[]) {
    await menusService.updateMenu(id, { products });
  }

  async createMenu(customerId: string, date: string, products: Product[], serviceCode: string) {
    await menusService.createMenu({
      customerId,
      date,
      products,
      serviceCode
    });
  }
}
