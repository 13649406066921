




import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class LoginCallback extends Vue {
  mounted(): void {
    const accessToken = new URLSearchParams(this.$route.hash.substring(1)).get(
      "access_token"
    );
    if (accessToken) localStorage.token = accessToken;
    this.$router.push({
      path: "/",
      query: {},
    });
  }
}
