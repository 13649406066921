










































import { Menu } from "@/model/Menu";
import { Service } from "@/model/Service";
import { Zone } from "@/model/Zone";
import { Actions } from "@/store/actions";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { DATE_CONFIG } from "./menus.constants";

@Component
export default class ServiceSelector extends Vue {
  get dates(): Date[] {
    return this.$store.getters[Getters.MENUS_DATES];
  }

  get services(): Date[] {
    return this.$store.getters[Getters.SERVICES];
  }

  set date(nDate: Date | null) {
    this.$store.commit(`${Mutations.SELECT_DATE}`, nDate, { root: true });
    this.$store.commit(`${Mutations.SET_ZONE}`, null, { root: true });
    if (this.services.length === 1) {
      this.$store.commit(`${Mutations.SELECT_SERVICE}`, this.services[0], { root: true });
      this.$store.commit(`${Mutations.SET_ZONE}`, null, { root: true });
    }
    this.updateCurrentMenu();
  }

  get date(): Date | null {
    return this.$store.state.date;
  }

  set service(service: Service | null) {
    this.$store.commit(Mutations.SELECT_SERVICE, service);
    this.$store.commit(Mutations.SET_ZONE, null);
    this.updateCurrentMenu();
  }

  get service(): Service | null {
    return this.$store.state.service;
  }

  // get zones(): Zone[] {
  //   return this.$store.getters[Getters.ZONES];
  // }

  // set zone(zone: Zone | null) {
  //   this.$store.commit(Mutations.SET_ZONE, zone);
  // }

  // get zone(): Zone | null {
  //   return this.$store.getters[Getters.ZONE];
  // }

  makeServiceText(service: Service): string {
    return service.name;
  }

  makeDateText(date: Date): string {
    return date.toLocaleString("fr", DATE_CONFIG);
  }

  makeZoneText(zone: Zone): string {
    return zone.name;
  }

  updateCurrentMenu() {
    if (this.service && this.date) {
      const foundMenu = (this.$store.getters[Getters.MENUS] as Menu[]).find(
        (menu: Menu) =>
          menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
      );
      if (foundMenu) this.$store.dispatch(Actions.SET_CURRENT_MENU, foundMenu._id, { root: true });
    }
  }
}
