

























































































import Component from "vue-class-component";
import { Actions } from "@/store/actions";
import {
  ALLERGENS,
  PRODUCT_NUTRISCORE,
  ORIGINS,
  PRODUCT_TYPES_NAMES
} from "@/components/menus/menus.constants";
import { Mutations } from "@/store/mutations";
import { DbProduct } from "@/model/DbProduct";
import { Getters } from "@/store/getters";
import DbProductFormDialog from "../../../components/dbproducts/DbProductFormDialog.vue";
import Base from "../../../components/core/Base.vue";

@Component({
  components: {
    DbProductFormDialog
  }
})
export default class DbProductsTable extends Base {
  search = "";

  types = Object.entries(PRODUCT_TYPES_NAMES);

  allergens = Object.entries(ALLERGENS);

  origins = Object.entries(ORIGINS);

  nutriscores = Object.entries(PRODUCT_NUTRISCORE);

  selectedTypes: string[] = [];

  headers = [
    { text: "Type", sortable: true, value: "type" },
    { text: "Nom", sortable: true, value: "name" },
    { text: "Nutriscore", sortable: true, value: "nutriscore" },
    { text: "Prix", sortable: true, value: "price" },
    { text: "Allergènes", sortable: true, value: "allergens" },
    { text: "Certifications", sortable: true, value: "origins" },
    { text: "Accompagnement", sortable: false, value: "sides" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  get dbProducts(): DbProduct[] {
    return this.$store.getters[Getters.DB_PRODUCTS];
  }

  async mounted() {
    this.$store.dispatch(Actions.SET_DB_PRODUCTS);
  }

  getTypeName(enumType: string): string {
    return this.types.filter(item => item[0] === enumType)[0]
      ? this.types.filter(item => item[0] === enumType)[0][1]
      : "";
  }

  getNutriscore(number: string): string {
    if (number) return this.nutriscores.filter(item => item[0] === number.toString())[0][1];
    return "";
  }

  getAllergen(enumAllergen: string): string {
    const allergen = this.allergens.filter(item => item[0] === enumAllergen)[0];
    return allergen ? allergen[1] : "";
  }

  getOrigin(enumOrigin: string): string {
    const origin = this.origins.filter(item => item[0] === enumOrigin)[0];
    return origin ? origin[1] : "";
  }

  filteredProducts() {
    let { dbProducts } = this;
    if (this.search && this.search.length > 0) {
      dbProducts = dbProducts.filter(product =>
        product.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
    if (this.selectedTypes.length > 0)
      dbProducts = dbProducts.filter(product =>
        this.selectedTypes.some(selectedType => selectedType === product.type)
      );
    return dbProducts;
  }

  createProduct() {
    this.$store.commit(Mutations.FORM_DIALOG_DB, true);
  }

  editProduct(product: DbProduct) {
    this.$store.commit(Mutations.SELECT_DB_PRODUCT_ID, product._id);
    this.$store.commit(Mutations.FORM_DIALOG_DB, true);
  }
}
