
































import Base from "@/components/core/Base.vue";
import MenuFormDialogDuplicate from "@/components/menus/MenuFormDialogDuplicate.vue";
import MenuService from "@/components/menus/MenuService.vue";
import ProductFormDialog from "@/components/menus/ProductFormDialog.vue";
import ProductFormDialogDelete from "@/components/menus/ProductFormDialogDelete.vue";
import ProductFormDialogMove from "@/components/menus/ProductFormDialogMove.vue";
import ServiceSelector from "@/components/menus/ServiceSelector.vue";
import { Mutations } from "@/store/mutations";
import Component from "vue-class-component";

@Component({
  components: {
    ProductFormDialog,
    ProductFormDialogMove,
    MenuFormDialogDuplicate,
    ProductFormDialogDelete,
    ServiceSelector,
    MenuService
  }
})
export default class Menus extends Base {
  model = 0;

  menuCreationDialog = false;

  mounted(): void {
    //
  }

  createProduct() {
    this.$store.commit(Mutations.SELECT_PRODUCT_ID, null);
    this.$store.commit(Mutations.FORM_DIALOG, true);
  }

  get selected(): boolean {
    return this.$store.getters.selected;
  }
}
