export const Mutations = {
  SET_USER: "set-user",
  START_LOADING: "start-loading",
  RELEASE_LOADING: "release-loading",
  SET_LIST: "set-list",
  SELECT_SITE: "select-site",
  SELECT_SERVICE: "SELECT_SERVICE",
  SET_SITE: "set-site",
  SET_DRAWER: "set-drawer",
  SELECT_DATE: "sel-date",
  SELECT_PRODUCT_ID: "sel-pduct-id",
  SELECT_DB_PRODUCT_ID: "sel-db-pduct-id",
  FORM_DIALOG: "form-dialog",
  FORM_DIALOG_DB: "form-dialog-db",
  FORM_DIALOG_MOVE: "form-dialog-move",
  FORM_DIALOG_DUPLICATE: "form-dialog-duplicate",
  FORM_DIALOG_DELETE: "form-dialog-delete",
  SET_ARTICLES: "set-articles",
  SET_MENUS: "set-menus",
  SET_CURRENT_MENU: "set-current-menu",
  SET_ZONE: "set-zone",
  SET_BOOKINGS: "set-bookings",
  SET_BOOKING_CONFIGURATION: "set-booking-configuration",
  SET_DB_PRODUCTS: "set-dbProducts"
};
