






































































import Component from "vue-class-component";
import { Vue, Watch } from "vue-property-decorator";

import { SNACK_EVENT_NAME, SnackEvent, SnackType } from "@/events";
import { Menu } from "@/model/Menu";
import { Product } from "@/model/Product";
import { Service } from "@/model/Service";
import Site from "@/model/Site";
import { menusService } from "@/services/menus.service";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import { ArrayLengthRule, ValidationRule } from "@/validation/validation.rules";
import DateSelectorField from "./DateSelectorField.vue";
import ProductSearchField from "./ProductSearchField.vue";

@Component({
  components: {
    DateSelectorField,
    ProductSearchField
  }
})
export default class ProductFormDialogDelete extends Vue {
  menu = false;

  valid = false;

  products: Product[] = [];

  selectedProducts: Product[] = [];

  get site(): Site | null {
    return this.$store.getters[`${Getters.SITE}`];
  }

  get services(): Service[] {
    return this.$store.getters[`${Getters.SERVICES}`];
  }

  get service(): Service | null {
    return this.$store.getters[`${Getters.SERVICE}`];
  }

  get date(): Date | null {
    return this.$store.state.date;
  }

  get currentMenu(): Menu | null {
    return this.$store.getters[`${Getters.CURRENT_MENU}`];
  }

  @Watch("formDialog")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      this.products = this.currentMenu ? this.currentMenu.products : [];
    }
  }

  async submit() {
    if (!this.site || !this.currentMenu) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    const { site, currentMenu } = this;

    Promise.resolve().then(() => {
      return menusService
        .removeProductsFromMenu(
          currentMenu._id || "",
          this.selectedProducts.map(product => product._id || "")
        )
        .then(() => {
          return "Produit/s modifié/s avec succès";
        })
        .catch(error => {
          this.snack(
            `Erreur lors de la mise à jour du menu (code ${error.response?.status})`,
            SnackType.ERROR,
            5000
          );
          throw error;
        })
        .finally(() => {
          this.close();
        })
        .then(async okMessage => {
          this.$store.commit(
            `${Mutations.SET_MENUS}`,
            await menusService.getMenus(site.customerId),
            { root: true }
          );
          const newCurrentMenu = (this.$store.getters[Getters.MENUS] as Menu[]).find(
            (menu: Menu) =>
              menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
          );
          if (newCurrentMenu && newCurrentMenu._id) {
            const updatedCurrentMenu = await menusService
              .getCurrentMenu(newCurrentMenu._id)
              .catch(e => {
                this.snack(
                  `Erreur lors de la mise à jour du menu. (${e.response?.status}) Actualisez la page.`,
                  SnackType.ERROR,
                  5000
                );
              });
            this.$store.commit(Mutations.SET_CURRENT_MENU, updatedCurrentMenu);
          }
          if (okMessage) this.snack(okMessage);
        })
        .finally(() => {
          this.$store.commit(`${Mutations.RELEASE_LOADING}`, { root: true });
        });
    });
  }

  close() {
    this.selectedProducts = [];
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
    this.formDialog = false;
  }

  set formDialog(v: boolean) {
    this.$store.commit(`${Mutations.FORM_DIALOG_DELETE}`, v, { root: true });
  }

  get formDialog(): boolean {
    return this.$store.getters[`${Getters.FORM_DIALOG_DELETE}`];
  }

  get productsRule(): ValidationRule[] {
    return [ArrayLengthRule];
  }

  set isAllProducts(event: boolean) {
    this.selectedProducts = event ? this.products : [];
  }

  get isAllProducts() {
    return this.selectedProducts.length === this.products.length;
  }

  snack(message: string, type = SnackType.SUCCESS, timeout = 3500, multiline = false) {
    this.$root.$emit(SNACK_EVENT_NAME, new SnackEvent(message, type, timeout, multiline));
  }

  get maxWidth(): string {
    if (this.$vuetify.breakpoint.xl) return "55%";
    if (this.$vuetify.breakpoint.lgAndUp) return "65%";
    return "100%";
  }
}
