import { User } from "@/model/User";
import { SecureService } from "./secure.service";

export class UsersService extends SecureService {
  constructor() {
    super({
      baseURL: process.env.VUE_APP_API_URL
    });
  }

  async getMyUser(): Promise<User> {
    return (await this.client.get<User>("/auth/me")).data;
  }
}

export const usersService = new UsersService();
