



















import { SnackType } from "@/events";
import { menusService } from "@/services/menus.service";
import { Mutations } from "@/store/mutations";
import Component from "vue-class-component";
import Base from "../core/Base.vue";

@Component
export default class ProductDelete extends Base {
  deleteConfirmation = false;

  async deleteProduct() {
    const menuId = this.currentMenu?._id;
    const productId = this.product?._id;
    const siteCustomerId = this.site?.customerId;
    const { currentMenu } = this;
    if (!productId || !menuId || !siteCustomerId || !currentMenu) return;

    this.startLoading();
    await menusService
      .removeProductFromMenu(menuId, productId)
      .then(async () => {
        console.log();

        return currentMenu.products.length > 1
          ? menusService.getCurrentMenu(menuId)
          : Promise.resolve(null);
      })
      .then(newCurrentMenu => {
        this.$store.commit(Mutations.SET_CURRENT_MENU, newCurrentMenu);
      })
      .then(() => {
        this.snack("Produit supprimé avec succès");
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$store.commit(Mutations.FORM_DIALOG, false);
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
