import { Menu } from "@/model/Menu";
import { Product } from "@/model/Product";
import Site from "@/model/Site";
import SiteInList from "@/model/SiteInList";
import { SecureService } from "./secure.service";

export class MenusService extends SecureService {
  constructor() {
    super({
      baseURL: process.env.VUE_APP_API_URL
    });
  }

  async getSitesList(): Promise<SiteInList[]> {
    return (await this.client.get<SiteInList[]>("/sites/list")).data;
  }

  async getSite(customerId: string): Promise<Site> {
    return (await this.client.get<Site>(`/sites/${customerId}`)).data;
  }

  async updateSite(customerId: string, site: Partial<Site>) {
    return (await this.client.patch<Site>(`/sites/${customerId}`, site)).data;
  }

  async getMenus(customerId: string) {
    return (await this.client.get<Menu[]>(`/menus/site/${customerId}`)).data;
  }

  async getCurrentMenu(menuId: string) {
    return (await this.client.get<Menu>(`/menus/${menuId}`)).data;
  }

  async createMenu(menu: Menu) {
    return (await this.client.post<Menu>(`/menus`, menu)).data;
  }

  async updateMenu(id: string, menu: Partial<Menu>) {
    return (await this.client.patch<Menu>(`/menus/${id}`, menu)).data;
  }

  async addProductToMenu(menuId: string, product: Product) {
    return (await this.client.post<Menu>(`/menus/${menuId}/products`, product)).data;
  }

  async updateProductInMenu(menuId: string, productId: string, product: Partial<Product>) {
    return (await this.client.patch<Menu>(`/menus/${menuId}/products/${productId}`, product)).data;
  }

  async removeProductFromMenu(menuId: string, productId: string) {
    return (await this.client.delete<Menu>(`/menus/${menuId}/products/${productId}`)).data;
  }

  async moveProducts(
    menuId: string,
    date: string,
    serviceCode: string,
    customerId: string,
    productIds: string[]
  ) {
    return (
      await this.client.post<Menu>(`/menus/move`, {
        menuId,
        serviceCode,
        date,
        customerId,
        productIds
      })
    ).data;
  }

  async duplicateMenus(
    startDateSource: string,
    endDateSource: string,
    customerId: string,
    serviceCode: string,
    startDateDest: string,
    customerIdsDest: string[],
    productTypes?: string[]
  ) {
    return (
      await this.client.post<Menu>(`/menus/duplicate`, {
        startDateSource,
        endDateSource,
        customerId,
        serviceCode,
        startDateDest,
        customerIdsDest,
        productTypes
      })
    ).data;
  }

  async removeProductsFromMenu(menuId: string, productsId: string[]) {
    return (await this.client.delete<Menu>(`/menus/${menuId}/products/`, { data: productsId }))
      .data;
  }
}

export const menusService = new MenusService();
