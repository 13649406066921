var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Menu "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.createProduct}},[_vm._v(" Ajouter un Produit ")])],1),_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts(),"sort-by":"type","no-data-text":"Aucun produit d'enregistrés","no-results-text":"Aucun produit trouvés","header-props":{ sortByText: 'Trié par' },"footer-props":{
      itemsPerPageText: 'Produit par page',
      itemsPerPageAllText: 'Tous',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"d-flex"},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"[1]","item-value":"[0]","label":"Type","multiple":"","chips":"","hide-details":"auto","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item[1]))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTypes.length - 1)+" autres) ")]):_vm._e()]}}]),model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":""},on:{"click":_vm.openMoveProductsModal}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-content-cut")])],1)]}}])},[_c('span',[_vm._v("Déplacer un/des produit/s")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":""},on:{"click":_vm.openDuplicateProductsModal}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-content-duplicate")])],1)]}}])},[_c('span',[_vm._v("Dupliquer un/des menu/s")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":""},on:{"click":_vm.openDeleteProductsModal}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Supprimer des produits")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un produit...","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeName(item.type))+" ")]}},{key:"item.nutriscore",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNutriscore(item.nutriscore))+" ")]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.price ? item.price + "€" : "")+" ")]}},{key:"item.allergens",fn:function(ref){
    var item = ref.item;
return _vm._l((item.allergens),function(allergen){return _c('v-chip',{key:allergen,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getAllergen(allergen))+" ")])})}},{key:"item.origins",fn:function(ref){
    var item = ref.item;
return _vm._l((item.origins),function(origin){return _c('v-chip',{key:origin,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getOrigin(origin))+" ")])})}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.zone",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getZone(item.zoneCode))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }