




































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Product } from "@/model/Product";
import { DbProduct } from "@/model/DbProduct";
import { productsService } from "@/services/products.service";
import { SnackType } from "@/events";
import Base from "../core/Base.vue";
import { PRODUCT_TYPES } from "./menus.constants";

@Component
export default class AddProductToDb extends Base {
  toggleMenu = false;

  @Prop({ required: true })
  mProduct!: Partial<Product>;

  async save() {
    this.startLoading();
    // TODO impl. creator ?
    const dbProduct: DbProduct = {
      name: this.mProduct.name || "",
      type: this.mProduct.type || PRODUCT_TYPES.MAIN,
      allergens: this.mProduct.allergens || [],
      origins: this.mProduct.origins || [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      price: this.mProduct.price ? parseFloat(this.mProduct.price as any) : undefined,
      nutriscore: this.mProduct.nutriscore
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          parseInt(this.mProduct.nutriscore as any, 10)
        : undefined,
      sides: this.mProduct.sides || undefined
    };

    if (this.isUpdate && this.mProduct.dbSourceId)
      await productsService.update(this.mProduct.dbSourceId, dbProduct).then(
        () => {
          this.snack("Modifications sauvegardées");
        },
        e => {
          this.snack(
            `Erreur pendant la sauvegarde de vos modifications (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    else
      await productsService.create(dbProduct).then(
        response => {
          this.snack(`Produit ajouté en base avec succès`);
          Vue.set(this.mProduct, "dbSourceId", response.data._id);
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible d'ajouter ce produit en base (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );

    this.releaseLoading();
    this.toggleMenu = false;
  }

  unlink() {
    Vue.set(this.mProduct, "dbSourceId", undefined);
    this.mProduct.dbSourceId = undefined;
  }

  get isUpdate(): boolean {
    return Boolean(this.mProduct.dbSourceId);
  }
}
