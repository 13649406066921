



























import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Login extends Vue {
  mounted(): void {
    const formUrl = process.env.VUE_APP_OIDC_LOGIN_FORM_URL;
    setTimeout(() => {
      window.location.href = formUrl ?? "/";
    }, 2000);
  }
}
