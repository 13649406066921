import { Booking } from "@/model/Booking";
import { BookingConfiguration } from "@/model/BookingConfiguration";
import { SecureService } from "./secure.service";

export class BookingsService extends SecureService {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_API_URL}/booking`
    });
  }

  async createBookingConfiguration(bookingConfiguration: BookingConfiguration) {
    return (await this.client.post<BookingConfiguration>(`/configuration`, bookingConfiguration))
      .data;
  }

  async updateBookingConfiguration(bookingConfiguration: Partial<BookingConfiguration>) {
    return (await this.client.patch<BookingConfiguration>(`/configuration`, bookingConfiguration))
      .data;
  }

  async deleteBookingConfiguration(customerId: string) {
    return (await this.client.delete<BookingConfiguration>(`/configuration/${customerId}`)).data;
  }

  async getBookingConfiguration(customerId: string) {
    return (await this.client.get<BookingConfiguration>(`/configuration/${customerId}`)).data;
  }

  async getBookings(customerId: string): Promise<Booking[]> {
    return (await this.client.get<Booking[]>(`/list/${customerId}`)).data;
  }

  async validateBooking(bookingId: string) {
    return (await this.client.patch<Booking>(`/validate/${bookingId}`)).data;
  }

  async cancelBooking(bookingId: string) {
    return (await this.client.delete<Booking>(`/${bookingId}`)).data;
  }
}

export const bookingsService = new BookingsService();
