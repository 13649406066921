

































import Vue from "vue";
import Component from "vue-class-component";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import SiteSelector from "./SiteSelector.vue";

@Component({
  components: { SiteSelector },
})
export default class CoreAppBar extends Vue {
  get email(): string {
    return this.$store.state.user?.email;
  }

  switchDrawer(): void {
    this.$store.commit(Mutations.SET_DRAWER, !this.$store.state.drawer);
  }

  get isSuperadmin(): boolean {
    return this.$store.getters[Getters.IS_SUPERADMIN];
  }
}
