
import Vue from "vue";
import Component from "vue-class-component";
import { Product } from "@/model/Product";
import { Service } from "@/model/Service";
import Site from "@/model/Site";
import SiteInList from "@/model/SiteInList";
import { User } from "@/model/User";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import { Menu } from "@/model/Menu";
import { Zone } from "@/model/Zone";
import { SnackEvent, SnackType, SNACK_EVENT_NAME } from "@/events";
import { Article } from "@/model/Article";
import { DbProduct } from "@/model/DbProduct";

@Component
export default class Base extends Vue {
  get site(): Site | null {
    return this.$store.state.site;
  }

  get services(): Service[] {
    return this.$store.getters[Getters.SERVICES];
  }

  get zones(): Zone[] {
    return this.$store.getters[Getters.ZONES];
  }

  get user(): User | undefined {
    return this.$store.state.user;
  }

  get date(): Date | null {
    return this.$store.state.date;
  }

  get zone(): Zone {
    return this.$store.getters[Getters.ZONE];
  }

  get currentMenu(): Menu | null {
    return this.$store.getters[Getters.CURRENT_MENU];
  }

  get product(): Product | null {
    return this.$store.getters[Getters.PRODUCT];
  }

  get dbProduct(): DbProduct | null {
    return this.$store.getters[Getters.DB_PRODUCT];
  }

  get list(): SiteInList[] {
    return this.$store.state.list;
  }

  get service(): Service | null {
    return this.$store.getters[Getters.SERVICE];
  }

  get loading(): boolean {
    return this.$store.getters[Getters.LOADING];
  }

  get articles(): Article[] {
    return this.$store.getters[Getters.ARTICLES];
  }

  get isAdmin(): boolean {
    return this.$store.getters[Getters.IS_ADMIN];
  }

  get maxWidth(): string {
    if (this.$vuetify.breakpoint.xl) return "55%";
    if (this.$vuetify.breakpoint.lgAndUp) return "65%";
    return "100%";
  }

  startLoading() {
    this.$store.commit(Mutations.START_LOADING);
  }

  releaseLoading() {
    this.$store.commit(Mutations.RELEASE_LOADING);
  }

  snack(message: string, type = SnackType.SUCCESS, timeout = 3500, multiline = false) {
    this.$root.$emit(SNACK_EVENT_NAME, new SnackEvent(message, type, timeout, multiline));
  }
}
