






import { Product } from "@/model/Product";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Base from "../core/Base.vue";
import MenuTable from "./MenuTable.vue";

@Component({
  components: {
    MenuTable
  }
})
export default class MenuService extends Base {
  products: Product[] = [];

  @Watch("$store.state.currentMenu")
  getNewProducts(): void {
    this.products = this.currentMenu ? this.currentMenu.products : [];
  }

  mounted() {
    this.getNewProducts();
  }
}
