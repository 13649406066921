import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export abstract class SecureService {
  client: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.client = axios.create(config);
    this.client.interceptors.request.use(iConfig => {
      const nConfig = iConfig;
      if (localStorage.apikey)
        nConfig.headers = {
          ...nConfig.headers,
          "x-api-key": localStorage.apikey
        };
      if (localStorage.token)
        nConfig.headers = {
          ...nConfig.headers,
          authorization: `Bearer ${localStorage.token}`
        };
      return nConfig;
    });
  }
}
