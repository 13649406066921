import store from "@/store";
import { Getters } from "@/store/getters";
import ExportMenus from "@/views/dashboard/views/ExportMenus.vue";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Admin from "../views/Admin.vue";
import Login from "../views/Login.vue";
import LoginCallback from "../views/LoginCallback.vue";
import Index from "../views/dashboard/Index.vue";
import Articles from "../views/dashboard/views/Articles.vue";
import Bookings from "../views/dashboard/views/Bookings.vue";
import ConfigBooking from "../views/dashboard/views/ConfigBooking.vue";
import DbProductsTable from "../views/dashboard/views/DbProductsTable.vue";
import Home from "../views/dashboard/views/Home.vue";
import ImportMenus from "../views/dashboard/views/ImportMenus.vue";
import Menus from "../views/dashboard/views/Menus.vue";

Vue.use(VueRouter);

function beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters[Getters.SITE]) {
    next("/");
  } else {
    next();
  }
}

function isAdmin(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters[Getters.IS_ADMIN]) {
    next("/");
  } else {
    next();
  }
}
const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        name: "Home.default",
        component: Home
      },
      {
        path: "menus",
        name: "Home.menus",
        component: Menus,
        beforeEnter
      },
      {
        path: "menus/import",
        name: "Home.import",
        component: ImportMenus,
        beforeEnter
      },
      {
        path: "menus/export",
        name: "Home.export",
        component: ExportMenus,
        beforeEnter
      },
      {
        path: "produits",
        name: "Home.products",
        component: DbProductsTable,
        beforeEnter: isAdmin
      },
      {
        path: "articles",
        name: "Home.articles",
        component: Articles,
        beforeEnter
      },
      {
        path: "reservations",
        name: "Home.bookings",
        component: Bookings,
        beforeEnter
      },
      {
        path: "reservations/configuration",
        name: "Home.bookingConfiguration",
        component: ConfigBooking,
        beforeEnter
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  {
    path: "/login/callback",
    name: "Login callback",
    component: LoginCallback
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
