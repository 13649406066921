






















































































































import { Product } from "@/model/Product";
import { Zone } from "@/model/Zone";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ALLERGENS, ORIGINS, PRODUCT_NUTRISCORE, PRODUCT_TYPES_NAMES } from "./menus.constants";

@Component
export default class MenuTable extends Vue {
  @Prop() readonly products!: Product[];

  search = "";

  types = Object.entries(PRODUCT_TYPES_NAMES);

  allergens = Object.entries(ALLERGENS);

  origins = Object.entries(ORIGINS);

  nutriscores = Object.entries(PRODUCT_NUTRISCORE);

  selectedTypes: string[] = [];

  headers = [
    { text: "Type", sortable: true, value: "type" },
    { text: "Lieu de distribution", sortable: false, value: "zone" },
    { text: "Nom", sortable: true, value: "name" },
    { text: "Nutriscore", sortable: true, value: "nutriscore" },
    { text: "Prix", sortable: true, value: "price" },
    { text: "Allergènes", sortable: true, value: "allergens" },
    { text: "Certifications", sortable: true, value: "origins" },
    { text: "Accompagnement", sortable: false, value: "sides" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  get zones(): Zone[] {
    return this.$store.getters[Getters.ZONES];
  }

  getTypeName(enumType: string): string {
    const type = this.types.filter(item => item[0] === enumType)[0];
    return type ? type[1] : "";
  }

  getNutriscore(number: string): string {
    if (number) return this.nutriscores.filter(item => item[0] === number.toString())[0][1];
    return "";
  }

  getAllergen(enumAllergen: string): string {
    const allergen = this.allergens.filter(item => item[0] === enumAllergen)[0];
    return allergen ? allergen[1] : "";
  }

  getOrigin(enumOrigin: string): string {
    const origin = this.origins.filter(item => item[0] === enumOrigin)[0];
    return origin ? origin[1] : "";
  }

  filteredProducts() {
    let { products } = this;
    if (this.search && this.search.length > 0) {
      products = products.filter(product => product.name.includes(this.search));
    }
    if (this.selectedTypes.length > 0)
      products = products.filter(product =>
        this.selectedTypes.some(selectedType => selectedType === product.type)
      );
    return products;
  }

  getZone(zoneCode: string): string {
    const productZone = this.zones.filter(zone => zone.code === zoneCode)[0];
    return productZone ? productZone.name : "";
  }

  createProduct() {
    this.$store.commit(Mutations.SELECT_PRODUCT_ID, null);
    this.$store.commit(Mutations.FORM_DIALOG, true);
  }

  editProduct(product: Product) {
    this.$store.commit(Mutations.SELECT_PRODUCT_ID, product._id);
    this.$store.commit(Mutations.FORM_DIALOG, true);
  }

  openMoveProductsModal() {
    this.$store.commit(Mutations.FORM_DIALOG_MOVE, true, { root: true });
  }

  openDuplicateProductsModal() {
    this.$store.commit(Mutations.FORM_DIALOG_DUPLICATE, true, { root: true });
  }

  openDeleteProductsModal() {
    this.$store.commit(Mutations.FORM_DIALOG_DELETE, true, { root: true });
  }
}
