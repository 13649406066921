






























import { Getters } from "@/store/getters";
import Vue from "vue";
import Component from "vue-class-component";
import Changelog from "../../../components/dashboard/Changelog.vue";

@Component({
  components: { Changelog }
})
export default class Home extends Vue {
  get selected(): boolean {
    return this.$store.getters[Getters.SELECTED];
  }

  get hasSites(): boolean {
    return this.$store.getters[Getters.HAS_SITES];
  }

  get isOauth(): boolean {
    return this.$store.getters[Getters.IS_OAUTH];
  }
}
