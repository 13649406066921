import { PRODUCT_TYPES } from "@/components/menus/menus.constants";
import { Article } from "@/model/Article";
import { AUTH_TYPE } from "@/model/AuthType";
import { Booking } from "@/model/Booking";
import { BookingConfiguration } from "@/model/BookingConfiguration";
import { DbProduct } from "@/model/DbProduct";
import { Menu } from "@/model/Menu";
import { Product } from "@/model/Product";
import { Service } from "@/model/Service";
import Site from "@/model/Site";
import SiteInList from "@/model/SiteInList";
import { USER_ROLE } from "@/model/UserRole";
import { Zone } from "@/model/Zone";
import { articlesService } from "@/services/articles.service";
import { bookingsService } from "@/services/bookings.service";
import { menusService } from "@/services/menus.service";
import { productsService } from "@/services/products.service";
import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import { State } from "./State";
import { Actions } from "./actions";
import { Getters } from "./getters";
import { Mutations } from "./mutations";

Vue.use(Vuex);

const dState: State = {
  drawer: false,
  loaders: 0,
  formDialog: false,
  formDialogDb: false,
  formDialogMove: false,
  formDialogDuplicate: false,
  formDialogDelete: false,
  list: [],
  site: null,
  date: null,
  productId: null,
  dbProductId: null,
  service: null,
  articles: [],
  menus: [],
  currentMenu: null,
  zone: null,
  bookings: [],
  bookingConfiguration: null,
  dbProducts: []
};

export default new Vuex.Store({
  state: dState,
  mutations: {
    [Mutations.SET_USER]: (state: State, user) => {
      Vue.set(state, "user", user);
    },
    [Mutations.START_LOADING]: (state: State) => {
      state.loaders += 1;
    },
    [Mutations.RELEASE_LOADING]: (state: State) => {
      if (state.loaders > 0)
        setTimeout(() => {
          state.loaders -= 1;
        }, 500);
    },
    [Mutations.FORM_DIALOG]: (state: State, v: boolean) => {
      state.formDialog = v;
    },
    [Mutations.FORM_DIALOG_DB]: (state: State, v: boolean) => {
      state.formDialogDb = v;
    },
    [Mutations.FORM_DIALOG_MOVE]: (state: State, v: boolean) => {
      state.formDialogMove = v;
    },
    [Mutations.FORM_DIALOG_DUPLICATE]: (state: State, v: boolean) => {
      state.formDialogDuplicate = v;
    },
    [Mutations.FORM_DIALOG_DELETE]: (state: State, v: boolean) => {
      state.formDialogDelete = v;
    },
    [Mutations.SET_LIST]: (state: State, newList: SiteInList[]) => {
      state.list = newList;
    },
    [Mutations.SET_SITE]: (state: State, newSite: Site | null) => {
      state.site = newSite;
    },
    [Mutations.SET_DRAWER]: (state: State, newDrawerValue: boolean) => {
      state.drawer = newDrawerValue;
    },
    [Mutations.SELECT_DATE]: (state: State, newDate: Date) => {
      state.date = newDate;
    },
    [Mutations.SELECT_PRODUCT_ID]: (state: State, productId: string | null) => {
      state.productId = productId;
    },
    [Mutations.SELECT_DB_PRODUCT_ID]: (state: State, dbProductId: string | null) => {
      state.dbProductId = dbProductId;
    },
    [Mutations.SELECT_SERVICE]: (state: State, service: Service | null) => {
      state.service = service;
    },
    [Mutations.SET_ARTICLES]: (state: State, articles: Article[]) => {
      state.articles = articles;
    },
    [Mutations.SET_MENUS]: (state: State, menus: Menu[]) => {
      state.menus = menus;
    },
    [Mutations.SET_CURRENT_MENU]: (state: State, currentMenu: Menu) => {
      state.currentMenu = currentMenu;
      const { site } = state;
      if ((!state.date || !state.service) && site) {
        state.date = new Date(currentMenu.date);
        state.service =
          site?.services.filter(service => service.code === currentMenu.serviceCode)[0] || null;
      }
    },
    [Mutations.SET_ZONE]: (state: State, zone: Zone) => {
      state.zone = zone;
    },
    [Mutations.SET_BOOKINGS]: (state: State, bookings: Booking[]) => {
      state.bookings = bookings;
    },
    [Mutations.SET_BOOKING_CONFIGURATION]: (
      state: State,
      bookingConfiguration: BookingConfiguration
    ) => {
      state.bookingConfiguration = bookingConfiguration;
    },
    [Mutations.SET_DB_PRODUCTS]: (state: State, dbProducts: DbProduct[]) => {
      state.dbProducts = dbProducts;
    }
  },
  actions: {
    [Actions.SET_LIST]: (ctx, newList: SiteInList[]) => {
      ctx.commit(Mutations.SET_LIST, newList);
      if (newList.length === 1) {
        ctx.dispatch(Actions.SELECT_SITE, newList[0].customerId);
      }
    },
    [Actions.SELECT_SITE]: async (ctx, customerId: string | null) => {
      if (customerId) {
        const site = await menusService.getSite(customerId);
        if (site) {
          ctx.commit(Mutations.SET_SITE, site);
          ctx.dispatch(Actions.SET_ARTICLES, customerId);
          ctx.dispatch(Actions.SET_BOOKING_CONFIGURATION, customerId);
          ctx.dispatch(Actions.SET_BOOKINGS, customerId);
          ctx.dispatch(Actions.SET_MENUS, customerId);
          ctx.commit(Mutations.SELECT_SERVICE, null);
          ctx.commit(Mutations.SET_ZONE, null);
        }
      } else {
        ctx.commit(Mutations.SET_SITE, null);
        ctx.commit(Mutations.SET_ARTICLES, []);
        ctx.commit(Mutations.SET_BOOKING_CONFIGURATION, null);
        ctx.commit(Mutations.SET_BOOKINGS, []);
        ctx.commit(Mutations.SET_MENUS, []);
      }
    },
    [Actions.SET_MENUS]: async (ctx, customerId: string) => {
      const menus = await menusService.getMenus(customerId);
      ctx.commit(Mutations.SET_MENUS, menus || []);
      // ctx.dispatch(Actions.SET_CURRENT_MENU, menus[0]._id);
    },
    [Actions.SET_CURRENT_MENU]: async (ctx, menuId: string | null) => {
      ctx.commit(
        Mutations.SET_CURRENT_MENU,
        menuId ? await menusService.getCurrentMenu(menuId) : null
      );
    },
    [Actions.SET_ARTICLES]: async (ctx, customerId: string) => {
      ctx.commit(Mutations.SET_ARTICLES, await articlesService.getArticles(customerId));
    },
    [Actions.SET_BOOKINGS]: async (ctx, customerId: string) => {
      ctx.commit(Mutations.SET_BOOKINGS, await bookingsService.getBookings(customerId));
    },
    [Actions.SET_BOOKING_CONFIGURATION]: async (ctx, customerId: string) => {
      ctx.commit(
        Mutations.SET_BOOKING_CONFIGURATION,
        (await bookingsService.getBookingConfiguration(customerId)) || null
      );
    },
    [Actions.SET_DB_PRODUCTS]: async ctx => {
      ctx.commit(Mutations.SET_DB_PRODUCTS, await productsService.dbProducts());
    }
  },
  getters: {
    [Getters.LOADING]: state => state.loaders > 0,
    [Getters.FORM_DIALOG]: state => state.formDialog,
    [Getters.FORM_DIALOG_DB]: state => state.formDialogDb,
    [Getters.FORM_DIALOG_MOVE]: state => state.formDialogMove,
    [Getters.FORM_DIALOG_DUPLICATE]: state => state.formDialogDuplicate,
    [Getters.FORM_DIALOG_DELETE]: state => state.formDialogDelete,
    [Getters.SELECTED]: state => {
      return state.site !== null;
    },
    [Getters.DATE]: state => state.date,
    [Getters.SITE]: state => state.site,
    [Getters.SERVICE]: state => state.service,
    [Getters.MENUS_DATES]: (state, getters) => {
      const menus: Menu[] = getters[Getters.MENUS];
      if (menus) {
        return _.uniq(menus.map(menu => menu.date))
          .sort()
          .map(dateStr => new Date(dateStr));
      }
      return [];
    },
    [Getters.SERVICES]: (state, getters): Service[] => {
      const site: Site | undefined = getters[Getters.SITE];
      if (site) return site.services;
      return [];
    },
    [Getters.ZONE]: (state): Zone | null => state.zone,
    [Getters.ZONES]: (state, getters): Zone[] => {
      const site: Site | undefined = getters[Getters.SITE];
      if (site) return site.zones;
      return [];
    },
    [Getters.CURRENT_MENU]: state => state.currentMenu,
    [Getters.MENUS]: (state): Menu[] => state.menus,
    [Getters.PRODUCTS]: (state, getters) => (zoneCode: string) =>
      getters[Getters.CURRENT_MENU].products.filter(
        (product: Product) =>
          product.type !== PRODUCT_TYPES.FORMULA &&
          product.zoneCode === zoneCode &&
          !getters[Getters.FORMULA_ZONES].some((zone: Zone) => zone.code === product.zoneCode)
      ) || [],
    [Getters.FORMULA_ZONES]: (state, getters): Zone[] =>
      getters[Getters.SITE].zones.filter((zone: Zone) => zone.formula) || [],
    [Getters.FORMULA_PRODUCTS]: (state, getters) => (zoneCode: string) =>
      getters[Getters.CURRENT_MENU].products.filter(
        (product: Product) => product.zoneCode === zoneCode
      ) || [],
    [Getters.PRODUCT]: (state, getters) => {
      const menu: Menu | undefined = getters[Getters.CURRENT_MENU];
      if (menu) return menu.products.find(product => product._id === state.productId);
      return undefined;
    },
    [Getters.IS_OAUTH]: (state): boolean => state.user?.auth_type === AUTH_TYPE.BEARER,
    [Getters.HAS_SITES]: (state): boolean =>
      state.user !== undefined && state.user.sites.length > 0,
    [Getters.IS_SUPERADMIN]: state =>
      state.user?.roles && state.user.roles.includes(USER_ROLE.SUPERADMIN),
    [Getters.IS_ADMIN]: state => state.user?.roles && state.user.roles.includes(USER_ROLE.ADMIN),
    [Getters.ARTICLES]: (state): Article[] => state.articles,
    [Getters.BOOKINGS]: (state): Booking[] => state.bookings,
    [Getters.BOOKING_CONFIGURATION]: (state): BookingConfiguration | null =>
      state.bookingConfiguration,
    [Getters.DB_PRODUCTS]: (state): DbProduct[] => state.dbProducts,
    [Getters.DB_PRODUCT]: state =>
      state.dbProducts.find(product => product._id === state.dbProductId)
  },
  modules: {}
});
