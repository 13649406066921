





























import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DATE_CONFIG } from "./menus.constants";

@Component
export default class DateSelectorField extends Vue {
  menu = false;

  @Prop({ type: Array, default: () => [] })
  rules!: unknown[];

  get formattedValue(): string {
    if (this.value) return this.value.toLocaleString("fr-FR", DATE_CONFIG);
    return "";
  }

  get valueForDatepicker(): string {
    if (this.value) return this.value.toISOString().substring(0, 10);
    return "";
  }

  dateInput(dateStr: string) {
    this.menu = false;
    const date = new Date(dateStr);
    this.$store.commit(Mutations.SELECT_DATE, date);
  }

  get value(): Date | undefined {
    return this.$store.getters[Getters.DATE];
  }
}
