




































































import Base from "@/components/core/Base.vue";
import { DATE_CONFIG } from "@/components/menus/menus.constants";
import { Menu } from "@/model/Menu";
import Component from "vue-class-component";

@Component({})
export default class ExportMenus extends Base {
  weekList: { startDate: Date; endDate: Date }[] = [];

  startDate: Date | null = null;

  serviceCode: string | null = null;

  themeList = [
    //   {
    //   id: 'TRI',
    //   name: 'tribu.png'
    // },
    {
      id: "PAL",
      name: "La palette du chef"
    },
    {
      id: "ATE",
      name: "L'atelier des halles"
    },
    {
      id: "INS",
      name: "L'instant gourmand"
    }
  ];

  themeId: string | null = "";

  onDayExport = false;

  dayDate: Date | null = null;

  dayList: { startDate: Date; endDate: Date }[] = [];

  get menus(): Menu[] {
    return this.$store.state.menus;
  }

  get exportUrl(): string {
    return `${process.env.VUE_APP_EXPORT_URL}/?customerId=${this.site?.customerId}&serviceCode=${
      this.serviceCode
    }&date=${this.startDate?.toISOString()}&themeId=${this.themeId}${
      this.dayDate && this.onDayExport ? `&dayDate=${this.dayDate?.toISOString()}` : ""
    }`;
  }

  mounted() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const nextYear = new Date();
    nextYear.setHours(0, 0, 0, 0);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    this.weekList = this.getWeeksList(today, nextYear);
    if (this.services.length === 1) this.serviceCode = this.services[0].code;
  }

  getWeeksList(startDate: Date, endDate: Date) {
    const startDay = startDate.getDay();

    // Si la date de début n'est pas un lundi, on la décale jusqu'au prochain lundi
    if (startDay !== 1) {
      const daysToMonday = startDay === 0 ? 6 : 1 - startDay;
      startDate.setDate(startDate.getDate() + daysToMonday);
    }
    const weeks = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const weekStartDate = new Date(currentDate);
      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() + 6);
      weeks.push({
        startDate: weekStartDate,
        endDate: weekEndDate
      });
      currentDate.setDate(currentDate.getDate() + 7);
    }
    return weeks.filter(week =>
      this.menus.some(
        menu =>
          week.startDate.getTime() <= new Date(menu.date).getTime() &&
          week.endDate.getTime() >= new Date(menu.date).getTime()
      )
    );
  }

  onChangeStartDate() {
    if (!this.startDate) return;
    const endDate = new Date(this.startDate);
    endDate.setDate(endDate.getDate() + 6);

    const days = [];
    const currentDate = new Date(this.startDate);
    while (currentDate <= endDate) {
      const dayStartDate = new Date(currentDate);
      const dayEndDate = new Date(currentDate);
      dayEndDate.setDate(currentDate.getDate() + 1);
      days.push({
        startDate: dayStartDate,
        endDate: dayEndDate
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.dayList = days.filter(week =>
      this.menus.some(
        menu =>
          week.startDate.getTime() <= new Date(menu.date).getTime() &&
          week.endDate.getTime() >= new Date(menu.date).getTime()
      )
    );
  }

  getDaysList(startDate: Date, endDate: Date) {
    const startDay = startDate.getDay();

    // Si la date de début n'est pas un lundi, on la décale jusqu'au prochain lundi
    if (startDay !== 1) {
      const daysToMonday = startDay === 0 ? 6 : 1 - startDay;
      startDate.setDate(startDate.getDate() + daysToMonday);
    }
    const days = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const weekStartDate = new Date(currentDate);
      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() + 6);
      days.push({
        startDate: weekStartDate,
        endDate: weekEndDate
      });
      currentDate.setDate(currentDate.getDate() + 7);
    }
    return days.filter(day =>
      this.menus.some(
        menu =>
          day.startDate.getTime() <= new Date(menu.date).getTime() &&
          day.endDate.getTime() >= new Date(menu.date).getTime()
      )
    );
  }

  formatDate(date: Date) {
    return date.toLocaleString("fr-FR", DATE_CONFIG);
  }
}
