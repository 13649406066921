













import Base from "@/components/core/Base.vue";
import { User } from "@/model/User";
import { menusService } from "@/services/menus.service";
import { Actions } from "@/store/actions";
import Component from "vue-class-component";
import CoreAppBar from "../../components/core/CoreAppBar.vue";
import CoreNavigationDrawer from "../../components/core/CoreNavigationDrawer.vue";
import { usersService } from "../../services/users.service";
import { Mutations } from "../../store/mutations";

@Component({
  components: { CoreAppBar, CoreNavigationDrawer },
})
export default class Index extends Base {
  async mounted(): Promise<void> {
    // Chargement des données de base
    this.startLoading();
    usersService
      .getMyUser()
      .then(
        async (user: User) => {
          this.$store.commit(Mutations.SET_USER, user);
        },
        (error) => {
          if (error.response?.status === 401) {
            this.$router.push({
              path: "/login",
            });
          }
          throw error;
        }
      )
      .then(async () => {
        const sitesList = await menusService.getSitesList();
        this.$store.dispatch(Actions.SET_LIST, sitesList);
      })
      .then(async () => {
        this.releaseLoading();
      });
  }
}
