export const Getters = {
  SELECTED: "select",
  LOADING: "loading",
  FORM_DIALOG: "form-dialog",
  FORM_DIALOG_DB: "form-dialog-db",
  FORM_DIALOG_MOVE: "form-dialog-move",
  FORM_DIALOG_DUPLICATE: "form-dialog-duplicate",
  FORM_DIALOG_DELETE: "form-dialog-delete",
  IS_ADMIN: "is_a",
  IS_SUPERADMIN: "is_sa",
  DATE: "date",
  SITE: "site",
  SERVICES: "services",
  MENUS_DATES: "menu_dates",
  MENU: "menu",
  MENUS: "menus",
  SERVICE: "service",
  PRODUCT: "product",
  PRODUCTS: "products",
  ZONES_BY_CODE: "zones_by_code",
  ZONES: "zones",
  ARTICLES: "articles",
  CURRENT_MENU: "current-menu",
  FORMULA_PRODUCTS: "formula-products",
  FORMULA_ZONES: "formula-zones",
  ZONE: "zone",
  HAS_SITES: "has_sites",
  IS_OAUTH: "is_oauth",
  BOOKINGS: "bookings",
  BOOKING_CONFIGURATION: "booking-configuration",
  DB_PRODUCTS: "dbProducts",
  DB_PRODUCT: "dbProduct"
};
