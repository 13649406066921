




















































































































































































import _ from "lodash";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import SiteInList from "@/model/SiteInList";
import { articlesService } from "../../services/articles.service";
import {
  ValidationRule,
  NotEmptyRule,
  FileSizeRule,
  ArrayLengthRule
} from "../../validation/validation.rules";
import { Article } from "../../model/Article";
import { SnackType } from "../../events";
import { Mutations } from "../../store/mutations";
import Base from "../core/Base.vue";

@Component({ components: { VueEditor } })
export default class ArticleForm extends Base {
  @Prop() article!: Article;

  valid = false;

  deleteConfirmation = false;

  newArticle: Article = {
    customerIds: [],
    title: "",
    body: "",
    image: null,
    youtubeUrl: ""
  };

  customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline"],
    [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link"]
  ];

  get notEmptyRule(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get fileRule(): ValidationRule[] {
    return [NotEmptyRule, FileSizeRule];
  }

  get customerIdsRule(): ValidationRule[] {
    return [ArrayLengthRule];
  }

  set isGlobalArticle(event: boolean) {
    this.newArticle.customerIds = event ? ["*"] : [];
  }

  get isGlobalArticle() {
    return this.newArticle.customerIds[0] === "*";
  }

  set customerIds(sites: SiteInList[]) {
    this.newArticle.customerIds = sites.map(site => site.customerId);
  }

  get customerIds(): SiteInList[] {
    return this.isGlobalArticle
      ? [{ customerId: "*", name: "Tous les sites", createdAt: "", updatedAt: "" }]
      : this.list.filter(site => this.newArticle.customerIds.includes(site.customerId));
  }

  created() {
    this.initForm();
  }

  @Watch("article")
  opening(): void {
    this.initForm();
  }

  initForm() {
    if (this.article) {
      this.newArticle = _.cloneDeep(this.article);
      if (this.newArticle.youtubeUrl) this.newArticle.image = null;
    } else this.clear();
  }

  getYouTubeId(): string {
    if (this.article.youtubeUrl) {
      const arr = this.article.youtubeUrl.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
    }
    return "";
  }

  getThumbnail(): string {
    return `https://img.youtube.com/vi/${this.getYouTubeId()}/sddefault.jpg`;
  }

  trimField(prop: keyof Article): void {
    const key: keyof Article = prop;
    (this.newArticle[key] as string) = (this.newArticle[key] as string).trim() || "";
  }

  clear() {
    if (!this.article) {
      this.newArticle = {
        customerIds: [],
        title: "",
        body: "",
        image: null,
        youtubeUrl: ""
      };
    }
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
    this.deleteConfirmation = !this.deleteConfirmation;
  }

  close() {
    this.$emit("closeDialog");
    this.clear();
  }

  async save() {
    if (this.site)
      this.newArticle.customerIds = this.isAdmin
        ? this.newArticle.customerIds
        : [this.site.customerId];

    if (!this.newArticle.youtubeUrl || this.newArticle.image)
      this.newArticle.youtubeUrl = undefined;
    if (this.newArticle.image && typeof this.newArticle.image !== "string")
      this.newArticle.image = await this.uploadImage();
    if (this.article) {
      this.updateArticle();
    } else {
      this.createArticle();
    }
  }

  async uploadImage() {
    const articleFormData = new FormData();
    this.startLoading();
    if (this.newArticle.image) articleFormData.append("image", this.newArticle.image);
    const url = await articlesService.uploadImageArticle(articleFormData);
    this.releaseLoading();
    return url;
  }

  async createArticle() {
    if (this.site) {
      this.startLoading();
      await articlesService
        .createArticle(this.newArticle)
        .then(
          async () => {
            this.snack(`Article ajouté avec succès`);
            const updatedArticles = await articlesService
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .getArticles(this.site!.customerId)
              .catch(e => {
                this.snack(
                  `Erreur lors de la mise à jour des articles. (${e.response?.status}) Actualisez la page.`,
                  SnackType.ERROR,
                  5000
                );
              });
            this.$store.commit(Mutations.SET_ARTICLES, updatedArticles);
          },
          e => {
            this.snack(
              e.response?.data?.message ||
                `Impossible d'ajouter cet article (${e.response?.status})`,
              SnackType.ERROR
            );
          }
        )
        .finally(() => {
          this.close();
        });
    }
    this.releaseLoading();
  }

  async updateArticle() {
    if (this.newArticle._id && this.site) {
      this.startLoading();
      await articlesService
        .updateArticle(this.newArticle._id, this.newArticle)
        .then(
          async () => {
            this.snack(`Article modifié avec succès`);
            if (this.site) {
              const updatedArticles = await articlesService
                .getArticles(this.site.customerId)
                .catch(e => {
                  this.snack(
                    `Erreur lors de la mise à jour des articles. (${e.response?.status}) Actualisez la page.`,
                    SnackType.ERROR,
                    5000
                  );
                });
              this.$store.commit(Mutations.SET_ARTICLES, updatedArticles);
            }
          },
          e => {
            this.snack(
              e.response?.data?.message ||
                `Erreur pendant la sauvegarde de vos modifications (${e.response?.status})`,
              SnackType.ERROR
            );
          }
        )
        .finally(() => {
          this.close();
        });
    }
    this.releaseLoading();
  }

  async deleteArticle() {
    this.startLoading();
    if (this.newArticle._id) {
      await articlesService
        .deleteArticle(this.newArticle._id)
        .then(
          async () => {
            this.snack(`Article supprimé avec succès`);
            if (this.site) {
              const updatedArticles = await articlesService
                .getArticles(this.site.customerId)
                .catch(e => {
                  this.snack(
                    `Erreur lors de la mise à jour des articles. (${e.response?.status}) Actualisez la page.`,
                    SnackType.ERROR,
                    5000
                  );
                });
              this.$store.commit(Mutations.SET_ARTICLES, updatedArticles);
            }
          },
          e => {
            this.snack(
              e.response?.data?.message ||
                `Erreur pendant la suppression de votre article (${e.response?.status})`,
              SnackType.ERROR
            );
          }
        )
        .finally(() => {
          this.close();
        });
    }
    this.releaseLoading();
  }
}
