




































































import Component from "vue-class-component";
import Base from "@/components/core/Base.vue";
import ArticleCard from "@/components/articles/ArticleCard.vue";
import ArticleForm from "@/components/articles/ArticleForm.vue";

@Component({
  components: { ArticleCard, ArticleForm }
})
export default class Articles extends Base {
  search = "";

  dialog = false;

  openCard() {
    this.dialog = true;
  }
}
