
















































import Vue from "vue";
import Component from "vue-class-component";
import Site from "@/model/Site";
import { Actions } from "@/store/actions";

@Component
export default class SiteSelector extends Vue {
  selection?: Site = undefined;

  get email(): string {
    return this.$store.state.user?.email;
  }

  get list(): Site[] {
    return this.$store.state.list;
  }

  itemText(item: Site): string {
    return `${item.customerId} ${item.name}`;
  }

  selectSite(selection: Site | null): void {
    this.$store.dispatch(Actions.SELECT_SITE, selection?.customerId || null);
  }

  get site(): Site | null {
    return this.$store.state.site || undefined;
  }
}
