







































































































































































import Component from "vue-class-component";
import Base from "@/components/core/Base.vue";
import { Getters } from "@/store/getters";
import { BookingConfiguration } from "@/model/BookingConfiguration";
import { Slot } from "@/model/Slot";
import { bookingsService } from "@/services/bookings.service";
import { Mutations } from "@/store/mutations";
import { SnackType } from "@/events";
import { ValidationRule, NotEmptyRule, NotValidEmailRule } from "@/validation/validation.rules";
import BookingConfigurationSlots from "@/components/bookings/BookingConfigurationSlots.vue";
import { ClosedDate } from "@/model/ClosedDate";
import { WorkingDay } from "@/model/WorkingDay";
import { DefaultWorkingDays } from "@/components/bookings/bookings.const";
import BookingConfigurationClosedDates from "@/components/bookings/BookingConfigurationClosedDates.vue";
import { Actions } from "@/store/actions";

@Component({
  components: { BookingConfigurationSlots, BookingConfigurationClosedDates }
})
export default class ConfigBooking extends Base {
  get bookingConfiguration(): BookingConfiguration | null {
    return this.$store.getters[Getters.BOOKING_CONFIGURATION];
  }

  get notEmailRules(): ValidationRule[] {
    return [NotEmptyRule, NotValidEmailRule];
  }

  get notBookingDaysRule(): ValidationRule[] {
    return [NotEmptyRule];
  }

  form: {
    email: string | null | undefined;
    customerId: string;
    workingDays: number[];
    closedDates: ClosedDate[];
    bookableDays: number;
    slots: Slot[];
  } = {
    email: "",
    customerId: "",
    workingDays: [],
    closedDates: [],
    bookableDays: 0,
    slots: []
  };

  valid = false;

  workingDaysSelected = [];

  deleteConfirmation = false;

  mounted() {
    this.initForm();
  }

  initForm() {
    if (this.bookingConfiguration) {
      this.form = {
        email: this.bookingConfiguration.email,
        customerId: this.bookingConfiguration.customerId,
        bookableDays: this.bookingConfiguration.bookableDays,
        slots: this.bookingConfiguration.slots,
        closedDates: this.bookingConfiguration.closedDates,
        workingDays: this.initWorkingDays(this.bookingConfiguration.workingDays)
      };
    }
    if (!this.bookingConfiguration && this.site) {
      this.form = {
        email: "",
        customerId: this.site.customerId,
        bookableDays: 0,
        slots: [],
        closedDates: [],
        workingDays: []
      };
    }
  }

  initWorkingDays(workingDays: WorkingDay[]): number[] {
    return workingDays
      .filter(workingDay => workingDay.enable)
      .map(workingDay => workingDay.weekDay);
  }

  formatWorkingDays(days: number[]): WorkingDay[] {
    return DefaultWorkingDays.map(workingDay => ({
      weekDay: workingDay.weekDay,
      enable: days.some(day => day === workingDay.weekDay)
    }));
  }

  addSlot() {
    this.form.slots.push({
      disabled: false,
      startTime: "",
      endTime: "",
      seats: 0,
      seatsThreshold: 0
    });
  }

  deleteSlot(slot: Slot) {
    this.form.slots = this.form.slots.filter(
      slotItem =>
        !(
          slotItem.startTime === slot.startTime &&
          slotItem.endTime === slot.endTime &&
          slotItem.seats === slot.seats &&
          slotItem.seatsThreshold === slot.seatsThreshold &&
          slotItem.disabled === slot.disabled
        )
    );
  }

  addClosedDate() {
    const newDate = new Date();
    newDate.setHours(0, 0, 0, 0);
    this.form.closedDates.push({
      from: newDate.toISOString(),
      to: newDate.toISOString(),
      closedMessage: ""
    });
  }

  deleteClosedDate(closedDate: ClosedDate) {
    this.form.closedDates = this.form.closedDates.filter(
      closedDateItem =>
        !(closedDateItem.from === closedDate.from && closedDateItem.to === closedDate.to)
    );
  }

  async sendBookingConfiguration() {
    this.startLoading();
    if (this.site && this.form.email) {
      const bookingConfiguration: BookingConfiguration = {
        customerId: this.site.customerId,
        email: this.form.email,
        bookableDays: this.form.bookableDays,
        slots: this.form.slots,
        workingDays: this.formatWorkingDays(this.form.workingDays),
        closedDates: this.form.closedDates.map(closedDate => ({
          from: new Date(closedDate.from).toISOString(),
          to: new Date(closedDate.to).toISOString(),
          closedMessage: closedDate.closedMessage || undefined
        }))
      };

      if (this.bookingConfiguration) {
        await bookingsService.updateBookingConfiguration(bookingConfiguration).then(
          async () => {
            this.snack(`Configuration modifiée avec succès`);
            this.$store.dispatch(Actions.SET_BOOKING_CONFIGURATION, this.site?.customerId);
          },
          e => {
            this.snack(
              e.response?.data?.message ||
                `Erreur pendant la sauvegarde de vos modifications (${e.response?.status})`,
              SnackType.ERROR
            );
          }
        );
      } else {
        await bookingsService.createBookingConfiguration(bookingConfiguration).then(
          async () => {
            this.snack(`Configuration créée avec succès`);
            this.$store.dispatch(Actions.SET_BOOKING_CONFIGURATION, this.site?.customerId);
          },
          e => {
            this.snack(
              e.response?.data?.message ||
                `Erreur pendant la sauvegarde de vos modifications (${e.response?.status})`,
              SnackType.ERROR
            );
          }
        );
      }
      this.releaseLoading();
    }
  }

  async deleteBookingConfiguration() {
    this.startLoading();
    if (this.bookingConfiguration) {
      await bookingsService.deleteBookingConfiguration(this.bookingConfiguration.customerId).then(
        async () => {
          this.snack(`Configuration supprimée avec succès`);
          this.$store.commit(Mutations.SET_BOOKING_CONFIGURATION, null);
          if (this.$refs.form) {
            const form = this.$refs.form as HTMLFormElement;
            form.reset();
          }
          this.deleteConfirmation = false;
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Erreur pendant la suppression de la configuration (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
  }

  resetBookingConfiguration() {
    this.initForm();
  }
}
