import DishTypeImage from "@/assets/images/types/plats.svg";

export const DATE_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  DRINK: "BOI",
  // SIDE: "ACC",
  FORMULA: "FOR",
  DAIRY: "LAI",
  CHEESE: "FRO",
  SNACKING: "SNA",
  LOCKER: "LOC",
};

export const PRODUCT_TYPES_COLORS = {
  [PRODUCT_TYPES.STARTER]: "green lighten-5",
  [PRODUCT_TYPES.MAIN]: "blue lighten-5",
  [PRODUCT_TYPES.DESSERT]: "red lighten-5",
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  [PRODUCT_TYPES.DAIRY]: "Laitage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  // [PRODUCT_TYPES.SIDE]: "Accompagnement",
  [PRODUCT_TYPES.FORMULA]: "Spécial : prix formule",
  [PRODUCT_TYPES.SNACKING]: "Snacking",
  [PRODUCT_TYPES.LOCKER]: "Casiers connectés",
};

export const PRODUCT_NUTRISCORE = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
};

export const PRODUCT_TYPES_IMAGES = {
  [PRODUCT_TYPES.MAIN]: DishTypeImage,
};

export const ALLERGENS = {
  GLU: "Gluten",
  CRU: "Crustacés",
  OEU: "Oeuf",
  POI: "Poisson",
  ARA: "Arachides",
  SOJ: "Soja",
  LAI: "Lait",
  FCQ: "Fruits à coque",
  CEL: "Céleri",
  MOU: "Moutarde",
  SES: "Sésame",
  SUL: "Sulfites",
  LUP: "Lupin",
  MOL: "Mollusques",
};

export const ORIGINS = {
  BIO: "Bio",
  CUIVAP: "Cuisine vapeur",
  RECEQU: "Recette équilibrée",
  VIFRFR: "Viande fraîche et française",
  PROFRA: "Produit frais",
  LECUCH: "Légumes cuisinés par le chef",
  PROLOC: "Produit local",
  MENLAB: "Menu label",
  MENMAL: "Menu malin",
  MENPLA: "Menu plaisir",
  PATCHE: "Pâtisserie réalisée par le chef",
  PLAJOU: "Plat du jour",
  PECJOU: "Pêche du jour",
  RECCHE: "Recette réalisée par le chef",
  PROSAI: "Produit de saison",
  SANGLU: "Sans gluten",
  SAUCHE: "Sauce cuisinée par le chef",
  CUISPE: "Cuisine spectacle (show cooking)",
  VIBOFR: "Viande bovine française",
  RECVEG: "Recette végétarienne",
  EGALIM: "Egalim",
  PROLAB: "Produit labellisé",
  ANTGAS: "Anti-gaspi",
  VIAEU: "Viande EU",
  VIAFRA: "Viande française",
};
