






































































































































































































import Component from "vue-class-component";
import Base from "@/components/core/Base.vue";
import { DataTableHeader } from "vuetify";
import { Booking } from "@/model/Booking";
import { Getters } from "@/store/getters";
import { BookingConfiguration } from "@/model/BookingConfiguration";
import { Slot } from "@/model/Slot";
import { Watch } from "vue-property-decorator";
import { bookingsService } from "@/services/bookings.service";
import { Mutations } from "@/store/mutations";
import { SnackType } from "@/events";

@Component
export default class Bookings extends Base {
  get bookings(): Booking[] {
    return this.$store.getters[Getters.BOOKINGS];
  }

  get bookingConfiguration(): BookingConfiguration {
    return this.$store.getters[Getters.BOOKING_CONFIGURATION];
  }

  headers: DataTableHeader[] = [
    {
      text: "Nom",
      align: "start",
      value: "lastName"
    },
    { text: "Prénom", value: "firstName" },
    { text: "Email", value: "email", sortable: false },
    { text: "Téléphone", value: "mobile", sortable: false },
    { text: "Date", value: "date", sortable: true },
    { text: "Créneau", value: "slot", sortable: false },
    { text: "Nombre de place", value: "seats" },
    { text: "Commentaire", value: "comment", sortable: false },
    { text: "État", value: "valid" },
    { text: "Actions", value: "actions", sortable: false }
  ];

  status = [
    { text: "Validée", value: true },
    { text: "En attente de validation", value: false }
  ];

  menu = false;

  dialogDelete = false;

  search = "";

  selectedSlots: string[] = [];

  selectedRange: string[] = [];

  selectedStatus: boolean | null = null;

  selectedBooking: Booking | null = null;

  filteredBookings() {
    let { bookings } = this;

    if (this.selectedRange.length === 2) {
      const startDate = new Date(this.selectedRange[0]).getTime();
      const endDate = new Date(this.selectedRange[1]).getTime();
      bookings = bookings.filter(
        booking =>
          new Date(booking.date).getTime() >= startDate &&
          new Date(booking.date).getTime() <= endDate
      );
    }
    if (this.selectedSlots.length > 0) {
      bookings = bookings.filter(booking =>
        this.selectedSlots.some(
          slotStartTime => slotStartTime === this.getBookingSlot(booking.date)?.startTime
        )
      );
    }
    if (this.selectedStatus !== null) {
      bookings = bookings.filter(booking => booking.valid === this.selectedStatus);
    }

    if (this.search && this.search.length > 0) {
      bookings = bookings.filter(
        booking =>
          booking.lastName.includes(this.search) ||
          booking.firstName.includes(this.search) ||
          booking.email.includes(this.search) ||
          (booking.mobile && booking.mobile.includes(this.search))
      );
    }
    return bookings;
  }

  getBookingSlot(bookingDate: string): Slot | null {
    const date = new Date(bookingDate);
    if (date) {
      const startTime = `${date.getHours()}:${
        date.getMinutes() < 10
          ? date
              .getMinutes()
              .toString(10)
              .padStart(2, "0")
          : date.getMinutes()
      }`;
      return this.bookingConfiguration?.slots.find(slot => slot.startTime === startTime) || null;
    }
    return null;
  }

  @Watch("selectedRange")
  formatInputDate() {
    if (this.selectedRange.length === 2) {
      const [startYear, startMonth, startDay] = this.selectedRange[0].split("-");
      const startDate = `${startDay.padStart(2, "0")}/${startMonth.padStart(2, "0")}/${startYear}`;
      const [year, month, day] = this.selectedRange[1].split("-");
      const endDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      return `${startDate} au ${endDate}`;
    }
    return null;
  }

  formatBookingDate(bookingDate: string) {
    const date = new Date(bookingDate);
    if (date)
      return date.toLocaleString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
        timeZone: "Europe/Paris"
      });
    return "";
  }

  formatSlot(slot: Slot): string {
    return `${slot.startTime} - ${slot.endTime}`;
  }

  capitalizeFirstName(value: string) {
    let str = value.trim().toLocaleLowerCase();
    if (str?.length) {
      if (str.search(/\s|-/g) < 0) {
        return this.transform(str);
      }
      if (str.search("-") >= 0) {
        str = this.transform(str, "-");
      }
      if (str.search(" ") >= 0) {
        str = this.transform(str, " ");
      }
    }
    return str;
  }

  transform(value: string, separator?: string) {
    if (!separator) {
      return value[0].toLocaleUpperCase() + value.substr(1);
    }
    const resArr: string[] = [];
    const strArr = value.split(separator);
    strArr.forEach(element => {
      resArr.push(element[0]?.toLocaleUpperCase() + element.substr(1));
    });
    return resArr.join(separator);
  }

  formatNameContact(booking: Booking) {
    return `${this.capitalizeFirstName(booking.firstName)} ${booking.lastName.toUpperCase()}`;
  }

  async validateItemBooking(item: Booking) {
    this.selectedBooking = { ...item };
    this.startLoading();
    if (this.selectedBooking._id && this.site) {
      await bookingsService.validateBooking(this.selectedBooking._id).then(
        async () => {
          this.snack(`Réservation validée avec succès`);
          const updatedBookings = await bookingsService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .getBookings(this.site!.customerId)
            .catch(e => {
              this.snack(
                `Erreur lors de la mise à jour des réservations. (${e.response?.status}) Actualisez la page.`,
                SnackType.ERROR,
                5000
              );
            });
          this.$store.commit(Mutations.SET_BOOKINGS, updatedBookings);
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible de valider cette réservation (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
  }

  cancelItemBooking(item: Booking) {
    this.selectedBooking = { ...item };
    this.dialogDelete = true;
  }

  async cancelConfirmBooking() {
    this.startLoading();
    if (this.selectedBooking && this.selectedBooking._id && this.site) {
      await bookingsService.cancelBooking(this.selectedBooking._id).then(
        async () => {
          this.snack(`Réservation supprimée avec succès`);
          const updatedBookings = await bookingsService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .getBookings(this.site!.customerId)
            .catch(e => {
              this.snack(
                `Erreur lors de la mise à jour des réservations. (${e.response?.status}) Actualisez la page.`,
                SnackType.ERROR,
                5000
              );
            });
          this.$store.commit(Mutations.SET_BOOKINGS, updatedBookings);
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible de supprimer cette réservation (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
    this.closeDeleteDialog();
  }

  closeDeleteDialog() {
    this.selectedBooking = null;
    this.dialogDelete = false;
  }
}
