



















import Component from "vue-class-component";
import { Actions } from "@/store/actions";
import { SnackType } from "../../events";
import { Mutations } from "../../store/mutations";
import { productsService } from "../../services/products.service";

import Base from "../core/Base.vue";

@Component
export default class DbProductDelete extends Base {
  deleteConfirmation = false;

  async deleteProduct() {
    const dbProductId = this.dbProduct?._id;

    if (!dbProductId) return;
    this.startLoading();
    await productsService
      .delete(dbProductId)
      .then(() => {
        this.snack("Produit supprimé avec succès");
        this.$store.dispatch(Actions.SET_DB_PRODUCTS);
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$store.commit(Mutations.FORM_DIALOG_DB, false);
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
