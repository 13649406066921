





















































import Component from "vue-class-component";
import Base from "@/components/core/Base.vue";

@Component
export default class BookingConfigurationSlotsDelete extends Base {
  deleteConfirmation = false;

  showMenu = false;
}
