















import Vue from "vue";
import Component from "vue-class-component";
import { SnackEvent, SNACK_EVENT_NAME } from "@/events";

@Component({ name: "App" })
export default class App extends Vue {
  snackbar = false;

  snackTimeout = 2000;

  snackColor = "";

  snackMessage = "";

  snackMultiline = false;

  onSnack(snackEvent: SnackEvent) {
    this.snackTimeout = snackEvent.timeout;
    this.snackColor = snackEvent.color;
    this.snackMessage = snackEvent.message;
    this.snackMultiline = snackEvent.multiline;
    this.snackbar = true;
  }

  mounted() {
    this.$root.$on(SNACK_EVENT_NAME, this.onSnack);
  }

  beforeDestroy() {
    this.$root.$off(SNACK_EVENT_NAME, this.onSnack);
  }
}
